import React from 'react';
import Layout from '../components/layout';
import AboutAbamath from '../components/about-abamath';
import SEO from '../components/seo';

const Abamath = () => (
  <Layout>
    <SEO
      title="Abamath | The Company behind Code Championship"
      description="Abamath is the company behind Code Championship. Code is Sport. Learn more about the history of Abamath here."
      keywords={['abamath', 'abamath llc', 'computer coding']}
    />
    <h1>Abamath LLC</h1>
    <div className="stripe-hr thin" />
    <AboutAbamath />
  </Layout>
);

export default Abamath;
